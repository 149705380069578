@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap");

/* @media screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  html {
    transform: rotate(-90deg);
    transform-origin: left top;
    width: 100vh;
    overflow-x: hidden;
    position: absolute;
    top: 100%;
    left: 0;
  }
}
 */

.rotateScreen90 {
  background-color: white;
  transform: rotate(-90deg);
  transform-origin: left top;
  width: calc(100vh - 70px);
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  left: 0;
}

.rotateScreen270 {
  background-color: white;
  transform: rotate(90deg);
  transform-origin: right top;
  width: calc(100vh - 70px);
  height: 100vw;
  overflow-x: hidden;
  position: absolute;
  top: 100%;
  right: 0;
}
